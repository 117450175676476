var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('list-layout',{ref:"listLayout",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"tabArr":_vm.tabArr,"on-fetch":_vm.getList,"on-edit":_vm.handleAdd,"on-delete":_vm.onDelete,"on-put-back":_vm.onPutBack,"on-update":_vm.onUpdate},on:{"command":_vm.handleClickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('el-button',{staticClass:"top-btn",attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.handleAdd()}}},[_vm._v("添加音频")])]},proxy:true},{key:"tabletop",fn:function(ref){
var idList = ref.idList;
var list = ref.list;
return [(_vm.filterForm.tab === 'deleted')?_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":_vm.putBackSelected}},[_vm._v("还原")]):_vm._e(),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.saveUpdatePost(idList, 1)}}},[_vm._v("批量上架")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.saveUpdatePost(idList, 0)}}},[_vm._v("批量下架")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.batchChangeCategory(idList)}}},[_vm._v("批量修改分类")]),_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":_vm.deleteSelected}},[_vm._v("删除")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.addThirdPartyCategory(idList)}}},[_vm._v("新增标签")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.removeThirdPartyCategory(idList)}}},[_vm._v("删除标签")])]}},{key:"filter",fn:function(ref){
var idList = ref.idList;
return [_c('list-filter',{attrs:{"idList":idList,"filter-form":_vm.filterForm},on:{"update":_vm.ok}})]}},{key:"item_categories",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.categoriesText(row.categories)))]}}])}),_c('ChangeCategoryDialog',{attrs:{"activeIds":_vm.activeIds,"complete":_vm.ok},model:{value:(_vm.openChangeCategoryDialog),callback:function ($$v) {_vm.openChangeCategoryDialog=$$v},expression:"openChangeCategoryDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }